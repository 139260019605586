import test from './components/test'
import headerScroll from './components/headerScroll'
import hamburger from './components/hamburger'
import headerAccordion from './components/headerAccordion'
import headerAnchor from './components/headerAnchor'

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test()
    headerScroll()
    hamburger()
    headerAccordion()
    headerAnchor()
  },
  false
)

/**
 * Hamburger
 */
const hamburger = () => {
  const body = document.body;
  const hamburgerBtn = document.querySelector('.js-hamburger');
  const logo = document.querySelector('.js-logo');
  const links = document.querySelectorAll('.js-link');
  const header = document.querySelector('.js-header');
  const button = document.querySelector('.js-button');
  const linkText = document.querySelectorAll('.header__link-text');

  if (hamburgerBtn && logo && header && button) {
    hamburgerBtn.addEventListener('click', () => {
      [hamburgerBtn, logo, header, button].forEach(el => el.classList.toggle('active'));
      body.style.overflow = hamburgerBtn.classList.contains('active') ? 'hidden' : 'auto';
    });
  }

  window.addEventListener('resize', function() {
    if ((hamburgerBtn && logo && header && button) && window.innerWidth > 855) {
      [hamburgerBtn, logo, header, button].forEach(el => el.classList.remove('active'));
      body.style.overflow = 'auto';
    }
  });

  links.forEach(link => {
    if (hamburgerBtn) {
      hamburgerBtn.addEventListener('click', () => {
        link.classList.toggle('active');
      });
    }
  });  

  if (!header) return
  document.addEventListener('click', (e) => {
    let eventTarget = e.target as any;
    let menuTarget = header.contains(eventTarget);
    if (!menuTarget) {
      //@ts-ignore
      [hamburgerBtn, logo, header, button].forEach(el => el.classList.remove('active'));
      body.style.overflow = 'auto';
    }
  });

  if (!linkText) return 
  linkText.forEach(item => {
    item.addEventListener('click', () => {
      //@ts-ignore
      [hamburgerBtn, logo, header, button].forEach(el => el.classList.remove('active'));
    })
  })
}

export default hamburger
const headerAnchor = () => {
  const anchorLinks = document.querySelectorAll('.js-anchor');
  const header = document.querySelector('.js-header');
  const hamburger = document.querySelector('.js-hamburger');
  const rows = document.querySelectorAll('.drop-down');
  const body = document.body;

  if(anchorLinks.length > 0) {
    anchorLinks.forEach(item => {
      item.addEventListener('click', () => {
        header?.classList.remove('active');
        hamburger?.classList.remove('active');
        body.style.overflow = 'auto';
        [...rows].forEach(row => {
          const button = row.querySelector('.js-open');
          const link = row.querySelector('.header__sub-link-wrapper');
          link?.classList.remove('open');
          button?.classList.remove('open');
        });
      });
    });
  }
}

export default headerAnchor
  
/**
 * Header Scroll
 */
const headerScroll = () => {
  // const headerElements = document.querySelectorAll(".js-header, .js-logo, .js-links, .js-link, .js-button, .js-hamburger > span, .header__sub-link, .header__sub-link-wrapper");
  const header = document.querySelector('.js-header');
  const hamburger = document.querySelectorAll('.js-hamburger > span');
  const links = document.querySelectorAll('.js-links');
  const link = document.querySelectorAll('.js-link');
  const logo = document.querySelector('.js-logo');
  const buttons = document.querySelectorAll('.js-button');
  let lastScrollTop = document.documentElement.scrollTop;

  window.addEventListener("scroll", () => {
    let currentScroll = document.documentElement.scrollTop;

      if (currentScroll == 0 && header?.classList.contains('scrolled-up')) {
        header?.classList.remove("scrolled-up");
      } else if (currentScroll > lastScrollTop) {
        header?.classList.add("scrolled");
        header?.classList.remove("scrolled-up");
        logo?.classList.add("scrolled");
        [...hamburger].forEach(element => {
          element?.classList.add("scrolled");
        });
        [...links].forEach(element => {
          element?.classList.add("scrolled");
        });
        [...link].forEach(element => {
          element?.classList.add("scrolled");
        });
        [...buttons].forEach(button => {
          button?.classList.add("scrolled");
        });
      } else if (currentScroll < lastScrollTop) {
        header?.classList.remove("scrolled");
        header?.classList.add("scrolled-up");
        logo?.classList.remove("scrolled");
        [...hamburger].forEach(element => {
          element?.classList.remove("scrolled");
        });
        [...links].forEach(element => {
          element?.classList.remove("scrolled");
        });
        [...link].forEach(element => {
          element?.classList.remove("scrolled");
        });
        [...buttons].forEach(button => {
          button?.classList.remove("scrolled");
        });
      }
    
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  });
}

export default headerScroll
const headerAccordion = () => {
  const rows = document.querySelectorAll('.drop-down');

  [...rows].forEach(row => {
    const button = row.querySelector('.js-open');
    const link = row.querySelector('.header__sub-link-wrapper');
    if (!link || !button) return
    button.addEventListener('click', () => {
      link.classList.toggle('open')
      button.classList.toggle('open')
    })
  })
}

export default headerAccordion
  